<template>
  <v-layout class="d-flex flex-column ma-10">
    <div style="font-family: 'Poppins-Regular'; color: #423f63;">
      Welcome,
      {{
        profile.first_name + (profile.last_name ? " " + profile.last_name : "")
      }}!
    </div>
    <div
      style="font-family: 'Poppins-SemiBold'; font-size: 25px; color: #482684"
    >
      What are you interested in?
    </div>
    <v-row class="mt-5">
      <v-col
        cols="12"
        sm="6"
        md="3"
        v-for="(category, index) in categories"
        :key="index"
      >
        <v-hover v-slot="{ hover }">
          <div
            :class="{ hoverCard: hover, nonHoverCard: !hover }"
            class="pa-3 rounded-lg"
            @click="onSelect(category)"
          >
            <v-img :src="category.image" />
            <div class="my-3">{{ category.title }}</div>

            <div v-if="category.selected" class="check-icon">
              <v-icon>mdi-check</v-icon>
            </div>
          </div>
        </v-hover>
      </v-col>
    </v-row>
    <div
      style="display: flex; justify-content: flex-end; margin-top: 30px; margin-bottom: 20px;"
    >
      <v-btn color="primary" @click="onSaveInterests" :loading="loading"
        >Continue<v-icon size="15" class="ml-2">mdi-arrow-right</v-icon></v-btn
      >
    </div>
    <v-snackbar v-model="snackbar">
      {{ error }}
    </v-snackbar>
  </v-layout>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      categories: [],
      selected: "",
      loading: false,
      snackbar: false,
      error: null,
    };
  },
  methods: {
    ...mapActions({
      fetchAllCategories: "variable/fetchAllCategories",
      saveInterests: "user/editUser",
    }),
    onSelect(item) {
      item.selected = !item.selected;
      this.categories = [...this.categories];
    },
    onSaveInterests() {
      var params = [];
      params = this.categories.filter((c) => c.selected).map((c) => c.title);
      if (params.length == 0) {
        this.showSnackbar("Please select at least one interst to see events");
        return;
      }
      this.loading = true;
      this.saveInterests({ interests: params })
        .then(() => {
          this.loading = false;
          this.$router.push({ name: "home" });
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.response.data.message);
        });
    },
    showSnackbar(message) {
      this.error = message;
      this.snackbar = true;
    },
    setSelection() {
      if (!this.profile.interests.length) return;
      for (var i = 0; i < this.categories.length; i++) {
        if (this.profile.interests.includes(this.categories[i].title)) {
          this.categories[i].selected = true;
        }
      }
      console.log(this.categories, this.profile.interests);
    },
  },
  computed: {
    ...mapGetters({
      profile: "auth/getProfile",
      profileType: "auth/getType",
    }),
  },
  watch: {
    profile(newValue) {
      if (newValue) {
        this.setSelection();
      }
    },
  },
  mounted() {
    this.loading = true;
    this.fetchAllCategories()
      .then((res) => {
        this.loading = false;
        this.categories = res;
        this.setSelection();
      })
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error);
        }
      });
  },
};
</script>
<style scoped>
.hoverCard {
  background: #f3e9ff;
  color: #1c1c1a;
  font-family: "Poppins-SemiBold";
  position: relative;
}
.nonHoverCard {
  background: white;
  color: #1c1c1a;
  font-family: "Poppins-SemiBold";
  position: relative;
}
.check-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  height: 30px;
  background-color: white;
  display: flex;
  align-content: center;
  justify-content: center;
  border-radius: 20px;
  box-shadow: 2px 2px 5px;
}
</style>
